import React from "react";

import Brands from "./Products/Brands";

const Product = () => {
  return (
    <div id="product" className="content">
      <div
        className="container content-animated-3"
        style={{ paddingBottom: "1rem" }}
      >
        <div className="content-title">
          <h1>Nuestros Productos</h1>
        </div>

        <div>
          <p className="content-desc section-content">
            Nuestra empresa se enorgullece de trabajar con productos de alta calidad
            en las mejores marcas del mercado, con el objetivo de garantizar la
            satisfacción de nuestros clientes.
          </p>
        </div>
        <Brands></Brands>
        
      </div>
    </div>
  );
};

export default Product;
