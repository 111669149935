import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import Header from "./common/Header";
import Footer from "./common/Footer"

function App() {
  return (
    <Router>
      <Header></Header>
      <div id="page-container" className="fade show">
        <Routes>
          <Route path="/" element={<Main />} />
        </Routes>
      </div>
      <Footer></Footer>
    </Router>
  );
}

export default App;

