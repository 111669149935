import React from "react";

import Description from "./About/Description";
import Mision from "./About/Mission";
import Vision from "./About/Vision";
import Values from "./About/Values";

const About = () => {
  return (
    <div id="about" className="content" data-scrollview="true">
      <div className="container content-animated-3" style={{ paddingBottom: "1rem" }}>
        <div className="content-title">
          <h1>Nuestra Empresa</h1>
        </div>
      </div>
      <Description></Description>
      <div className="content-animated-2">
        <Mision></Mision>
      </div>
      <div className="content-animated-1">
        <Vision></Vision>
      </div>
      <div className="content-animated-2">
        <Values></Values>
      </div>
    </div>
  );
};

export default About;
