import React from "react";

const imgmission = "/assets/img/corporate/img-13.jpg";

const Mission = () => {
  return (
    <div id="mission" className="container section-space">
      <div className="row">
        <div className="col-md-8 col-space">
          <h1>Nuestra Misión</h1>
          <p className="section-content">
            En SoftBuilders, nuestra misión es ser el socio tecnológico
            preferido de las empresas, ofreciendo soluciones personalizadas y de
            calidad que impulsen su transformación digital y mejoren su
            competitividad en el mercado.
          </p>
        </div>
        <div className="col-md-4 col-space">
          <img src={imgmission} alt="Mission" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default Mission;
