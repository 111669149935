import React, { useEffect } from "react";

const Header = () => {
  const handleMenuClick = () => {
    document.querySelector(".navbar-toggle").click();
  };

  useEffect(() => {
    const handleBodyClick = () => {
      const navbar = document.querySelector(".navbar-collapse");
      if (navbar.classList.contains("show")) {
        document.querySelector(".navbar-toggle").click();
      }
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  return (
    <div
      id="header"
      className="header navbar navbar-transparent navbar-default navbar-inverse navbar-fixed-top navbar-expand-lg"
    >
      <div className="container">
        <a href="/" className="navbar-brand">
          <span className="img">
            <img src="/assets/img/logo/isotipo.png" alt="logo-softbuilders" />
          </span>
          <span className="brand-text">
            <span className="text-theme">SoftBuilders</span> IO
          </span>
        </a>
        <button
          type="button"
          className="navbar-toggle collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#header-navbar"
        >
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
        <div className="collapse navbar-collapse" id="header-navbar">
          <ul className="nav navbar-nav navbar-end">
            {/* <li className="nav-item dropdown">
                            <a className="nav-link active" href="#home" data-click="scroll-to-target" data-scroll-target="#home">HOME <b className="caret"></b></a>
                            <div className="dropdown-menu dropdown-menu-left animate__animated animate__fadeInDown">
                                <a className="dropdown-item" href="index.html">Page with Transparent Header</a>
                                <a className="dropdown-item" href="index_inverse_header.html">Page with Inverse Header</a>
                                <a className="dropdown-item" href="index_default_header.html">Page with White Header</a>
                                <a className="dropdown-item" href="extra_element.html">Extra Element</a>
                            </div>
                        </li> */}
            <li className="nav-item">
              <a
                className="nav-link"
                href="#home"
                data-click="scroll-to-target"
                onClick={handleMenuClick}
              >
                Inicio
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#about"
                data-click="scroll-to-target"
                onClick={handleMenuClick}
              >
                Nuestra Empresa
              </a>
            </li>
            {/* <li className="nav-item"><a className="nav-link" href="#team" data-click="scroll-to-target">TEAM</a></li> */}
            <li className="nav-item">
              <a
                className="nav-link"
                href="#product"
                data-click="scroll-to-target"
                onClick={handleMenuClick}
              >
                Productos
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#service"
                data-click="scroll-to-target"
                onClick={handleMenuClick}
              >
                Servicios
              </a>
            </li>
            {/* <li className="nav-item"><a className="nav-link" href="#work" data-click="scroll-to-target">WORK</a></li> */}
            <li className="nav-item">
              <a
                className="nav-link"
                href="#client"
                data-click="scroll-to-target"
                onClick={handleMenuClick}
              >
                Nuestros Clientes
              </a>
            </li>
            {/* <li className="nav-item"><a className="nav-link" href="#pricing" data-click="scroll-to-target">PRICING</a></li> */}
            <li className="nav-item">
              <a
                className="nav-link"
                href="#contact"
                data-click="scroll-to-target"
                onClick={handleMenuClick}
              >
                Contáctanos
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
