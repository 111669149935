import React from "react";

const imgvision = "/assets/img/corporate/img-6.jpg";

const Vision = () => {
  return (
    <div id="vision" className="container section-space">
      <div className="row">
      <div className="col-md-8 col-space">
          <h1>Nuestra Visión</h1>
          <p className="section-content">
            Ser reconocidos como líderes en el desarrollo de soluciones
            tecnológicas innovadoras, que ayuden a nuestros clientes a alcanzar
            sus metas empresariales y a construir un futuro digital exitoso.
          </p>
        </div>
        <div className="col-md-4 col-space">
          <img src={imgvision} alt="Vision" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default Vision;
