import React from "react";
import ContactUs from "./Contact/ContactFormDemo";

const Contact = () => {
  return (
    <div id="contact" className="content">
      <div className="container content-animated-3" style={{ paddingBottom: "1rem" }}>
        <div className="content-title">
          <h1>Contáctanos</h1>
        </div>
        <ContactUs></ContactUs>
      </div>
    </div>
  );
};

export default Contact;
