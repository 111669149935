import React from "react";
import brandGallery from "../../mocks/products/brands-logo.json"
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  FreeMode,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Brands = () => {
  return (
    <div className="col-space">
      <Swiper
        slidesPerView={"auto"}
        centeredSlides={true}
        spaceBetween={0}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        loop={true}
        effect={"fade"}
        speed={1000}
        freeMode={false}
        navigation={false}
        modules={[Autoplay, Pagination, FreeMode, Navigation, EffectFade]}
        className="mySwiper"
      >
        {brandGallery.map((brand) => (
          <SwiperSlide key={brand.id}>
            <img src={brand.image} alt={brand.alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Brands;
