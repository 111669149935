import React from "react";

const imgvalues = "/assets/img/corporate/img-9.jpg";

const Values = () => {
  return (
    <div id="values" className="container section-space">
      <div className="row">
        <div className="col-md-8 col-space">
          <h1>Nuestros Valores</h1>
          <p className="section-content">
            En SoftBuilders, la eficacia y la innovación son nuestro motor.
            Tomamos decisiones ágiles y actuamos con rapidez para ofrecer
            alternativas de calidad. Escuchamos atentamente a nuestros clientes,
            adaptándonos a sus necesidades reales. Trabajamos en equipo,
            generando un clima de confianza y respeto mutuo. Nuestro enfoque
            está siempre en el cliente, desarrollando soluciones que aumentan su
            satisfacción. Operamos con rigor y ética profesional, manteniendo la
            transparencia y accesibilidad en todo momento.
          </p>
        </div>
        <div className="col-md-4 col-space">
          <img src={imgvalues} alt="Vision" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default Values;
