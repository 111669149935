import React from "react";
import Home from "./Home";
import About from "./About";
import Product from "./Product";
import Service from "./Service";
import Client from "./Client";
import Contact from "./Contact";

const Main = () => {
  return (
    <>
      <Home></Home>
      <About></About>
      <Product></Product>
      <Service></Service>
      <Client></Client>
      <Contact></Contact>
    </>
  );
};

export default Main;
