import React from "react";

const Service = () => {
  return (
    <div id="service" className="content">
      <div className="container content-animated-3" style={{ paddingBottom: "1rem" }}>
        <div className="content-title">
          <h1>Nuestros Servicios</h1>
        </div>
      </div>
    </div>
  );
}

export default Service;
