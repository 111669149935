import React from "react";

const logo = "/assets/img/logo/logo-sb-a2.svg";
const Home = () => {
  return (
    <div id="home" className="content has-bg home">
      <div
        className="content-bg"
        style={{ backgroundImage: `url(/assets/img/bg/bg-home.jpg)` }}
        data-paroller="true"
        data-paroller-type="foreground"
        data-paroller-factor="-0.25"
      ></div>

      <div className="container home-content-sb content-animated-3">
        <img
          src={logo}
          className="home-logo-sb"
          alt="logo-sbc"
        ></img>
        <p> SoftBuilders IO </p>
      </div>
    </div>
  );
};

export default Home;
