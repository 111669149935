import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { CSSTransition } from "react-transition-group";

const Description = () => {
  const [isVisible, setVisible] = React.useState(false);

  const onChange = (visiblity) => {
    setVisible(visiblity);
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility>
      <CSSTransition
        in={isVisible}
        timeout={1000}
        id="description"
        className="container content-animated-3 "
        style={{ paddingBottom: "1rem" }}
      >
        <div>
          <p className="content-desc section-content">
            En SoftBuilders IO, transformamos tus ideas en innovación.
            <br /> Nuestro compromiso es ser tu socio estratégico para construir
            el futuro digital para tu empresa; ofreciendote soluciones a la
            medida de tus necesidades tecnológicas que te permitan alcanzar el
            éxito empresarial.
          </p>
        </div>
      </CSSTransition>
    </VisibilitySensor>
  );
};

export default Description;
