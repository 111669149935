import React from "react";

const ContactFormDemo = () => {
  return (
    <div className="row">
      <div className="col-lg-5" data-animation="true">
        <p className="section-content">
          Nos emociona la idea de poder trabajar contigo. Indicanos tu
          requerimiento y nos pondremos en contacto contigo a la brevedad
          posible.
        </p>
        <p className="section-content">
          <strong>SOFTBUILDERS IO</strong>
          <br />
          Quito - Ecuador
        </p>
        <p className="section-content">
          <a href="mailto:info@softbuilders.io" className="text-theme">
            info@softbuilders.io
          </a>
        </p>
      </div>

      <div
        className="col-lg-7 form-col content-title-form"
        data-animation="true"
      >
        <h2>Déjanos tus datos</h2>
        <iframe
          title="ContactForm"
          width="100%"
          height="700"
          src="https://426768dd.sibforms.com/serve/MUIFAHoKDWg_vZ6Ydc9zq295tx1J_QZUwR7fSozpgPLZ2Ooyl0Dl4Bl-GaXPK4yM6g9_8-WdlAa9rs6lcBVmU8GvNTR-ngHVWuyX-xM546OdVahD5Z5Wx06Ni5FOPt91OXel0OEOOc22Eo92TNfhvJxPjSiLMuZnnd2Z06WO7wIS7HkIgjTITVPlf3XkUoIFuAVrCliNUiB1QmWB"
          frameborder="0"
          allowfullscreen
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "100%",
          }}
        ></iframe>
      </div>
    </div>
  );
};

export default ContactFormDemo;
