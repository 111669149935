import React from "react";

const Footer = () => {
  return (
    <div id="footer" className="footer">
      <div className="container">
        <div className="footer-brand">
          <div className="footer-logo-sb">
            <img
              src="/assets/img/logo/logo-sb-a1.svg"
              alt="logo-softbuilders"
            />
          </div>
          SoftBuilders IO
        </div>
        <p>
          &copy; Copyright SoftBuilders IO - 2024 <br />
          Diseño y tema que genera impacto. Creado por{" "}
          <a href="#home">SoftBuilders</a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
