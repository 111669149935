import React from "react";

const Client = () => {
  return (
    <div id="client" className="content">
      <div className="container content-animated-3" style={{ paddingBottom: "1rem" }}>
        <div className="content-title">
          <h1>Nuestros Clientes</h1>
        </div>
      </div>
    </div>
  );
};

export default Client;
